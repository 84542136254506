<template>
  <div class="home">
    <div class="container-fluid">
      <span v-touch:swipe="swipeHandler">
          <div class="col-12 main">
              <div class="header col-12" style="z-index: 100">
                  <div class="row animate__animated animate__zoomIn">
                      <div class="col-6 pt-2 ps-5">
                          <img src="img/flutter-logo.png" width="450" class="logoImg" alt="Flutterlytics digital, premium marketing and consulting solutions, supported by our data, analytics and optimizations.">
                      </div>
                      <div class="col-6 text-end pe-5 pt-3">
                          <i class="fa circle-icon menuIconRight" @click="showMenuDialog" 
                            :class="{'fa-bars': !btnMenuDialogIcon, 'fa-xmark': btnMenuDialogIcon}"
                            style="cursor: pointer;"
                            >
                          </i>
                      </div>
                  </div>
              </div>

              <div class="row">
                  <div class="col-2 left text-center leftSideMenu">
                      <div>
                          <button type="button" :style="{'width':'140px', 'background-color': btnBackColor[0]}" @click="btnHome" class="btn btn-outline-secondary btn-rounded text-light menu-home">Home</button>
                          <br><br>
                          <button type="button" :style="{'width':'140px', 'background-color': btnBackColor[1]}" @click="btnAbout" class="btn btn-outline-secondary btn-rounded text-light menu-about">About</button>
                          <br><br>
                          <button type="button" :style="{'width':'140px', 'background-color': btnBackColor[2]}" @click="btnServices" class="btn btn-outline-secondary btn-rounded text-light menu-services">Services</button>
                          <br><br>
                          <button type="button" :style="{'width':'140px', 'background-color': btnBackColor[3]}" @click="btnContact" class="btn btn-outline-secondary btn-rounded text-light menu-contact">Contact</button>
                      </div>
                  </div>
                  <div class="col-8 middle text-center centerScreen" style="border: 0px solid yellow">
                          
                      <div v-if="centerView == 'home'" style="font-size: 75px; border: 0px solid green; height: 100%; padding-top: 0px;" class="centerHome"
                        :style="windowWidth < 600 ? 'width: 100%':''">

                          <div style="font-size: 90px; color: rgb(73, 231, 255); font-weight: 700;" class="animate__animated animate__slideInDown">
                            <div class="txtDigital">Digital</div>
                          </div>
                          <div style="font-weight: 500; margin-top: -10px" class="animate__animated animate__pulse txtMarketCons">
                            <u>Marketing</u> and 
                            <br> <u>Consulting</u> 
                            <br> Solutions
                          </div>
                          
                          <div style="border: 0px solid grey; margin-left: auto; margin-right: auto;" class="carouselDiv"
                            :style="windowWidth < 600 ? windowHeight < 750 ? 'margin-top: 3%' : 'margin-top: 10%' : 'margin-top: 15%'">
                            <div style="border-bottom: 1px solid grey; width: 220px; margin-left: auto; margin-right: auto;"
                              :style="windowWidth < 600 ? 'font-size: 16px !important;':'font-size: 18px !important;'">
                              Brands We Work With
                            </div>
                            <div v-if="windowWidth > 600">
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between;">
                                <img src="../assets/aliexpress.png" height="auto" width="105"/>
                                <img src="../assets/asics.png" height="auto" width="105"/>
                                <img src="../assets/calvin.png" height="auto" width="105"/>
                                <img src="../assets/hoka.png" height="auto" width="105"/>
                                <img src="../assets/nbastore.png" height="auto" width="105"/>
                              </div>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between;">
                                <img src="../assets/newbalance.png" height="auto" width="105"/>
                                <img src="../assets/samsung.png" height="auto" width="105"/>
                                <img src="../assets/shein.png" height="auto" width="105"/>
                                <img src="../assets/temu.png" height="auto" width="105"/>
                                <img src="../assets/tommy.png" height="auto" width="105"/>
                              </div>
                            </div>

                            <div v-else>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between; width: 100% !important; ">
                                <img src="../assets/aliexpress.png" height="auto" width="80"/>
                                <img src="../assets/asics.png" height="auto" width="80"/>
                                <img src="../assets/calvin.png" height="auto" width="80"/>
                                <img src="../assets/hoka.png" height="auto" width="80"/>
                              </div>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between; width: 100% !important;">
                                <img src="../assets/nbastore.png" height="auto" width="80"/>
                                <img src="../assets/newbalance.png" height="auto" width="80"/>
                                <img src="../assets/samsung.png" height="auto" width="80"/>
                                <img src="../assets/shein.png" height="auto" width="80"/>
                              </div>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content: center; width: 100% !important;">
                                <img src="../assets/temu.png" height="auto" width="100"/>
                                <img src="../assets/tommy.png" height="auto" width="100"/>
                              </div>
                            </div>
                          </div>
                      </div>

                      <!-- <div class="container" v-if="centerView == 'home'" >
                          <div style="font-size: 90px; color: rgb(73, 231, 255); font-weight: 700;" class="animate__animated animate__slideInDown inner">
                            <div class="txtDigital">Digital</div>
                          </div>
                          <div style="font-weight: 500; margin-top: -10px" class="animate__animated animate__pulse txtMarketCons inner">
                            <u>Marketing</u> and 
                            <br> <u>Consulting</u> 
                            <br> Solutions
                          </div>
                          
                          <div style="border: 1px solid grey; margin-left: auto; margin-right: auto;" class="inner"
                            :style="windowWidth < 600 ? 'margin-bottom: 0px !important':'margin-top: 10%'">
                            <div style="font-size: 18px !important; border-bottom: 1px solid grey; width: 220px; margin-left: auto; margin-right: auto;">
                              Brands We Work With
                            </div>
                            <div v-if="windowWidth > 600">
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between;">
                                <img src="../assets/aliexpress.png" height="auto" width="105"/>
                                <img src="../assets/asics.png" height="auto" width="105"/>
                                <img src="../assets/calvin.png" height="auto" width="105"/>
                                <img src="../assets/hoka.png" height="auto" width="105"/>
                                <img src="../assets/nbastore.png" height="auto" width="105"/>
                              </div>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between;">
                                <img src="../assets/newbalance.png" height="auto" width="105"/>
                                <img src="../assets/samsung.png" height="auto" width="105"/>
                                <img src="../assets/shein.png" height="auto" width="105"/>
                                <img src="../assets/temu.png" height="auto" width="105"/>
                                <img src="../assets/tommy.png" height="auto" width="105"/>
                              </div>
                            </div>

                            <div v-else>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between; width: 100% !important; ">
                                <img src="../assets/aliexpress.png" height="auto" width="80"/>
                                <img src="../assets/asics.png" height="auto" width="80"/>
                                <img src="../assets/calvin.png" height="auto" width="80"/>
                                <img src="../assets/hoka.png" height="auto" width="80"/>
                              </div>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content:space-between; width: 100% !important;">
                                <img src="../assets/nbastore.png" height="auto" width="80"/>
                                <img src="../assets/newbalance.png" height="auto" width="80"/>
                                <img src="../assets/samsung.png" height="auto" width="80"/>
                                <img src="../assets/shein.png" height="auto" width="80"/>
                              </div>
                              <div class="carou" style="display: flex; flex-wrap: wrap; justify-content: center; width: 100% !important;">
                                <img src="../assets/temu.png" height="auto" width="100"/>
                                <img src="../assets/tommy.png" height="auto" width="100"/>
                              </div>
                            </div>
                          </div>
                      </div> -->

                      <div v-if="centerView == 'about'" style="font-size: 70px; margin-top: -100px;">
                          <div class="animate__animated animate__pulse txtAbouts" style="font-weight: 500;">
                              Who <span style="color: rgb(73, 231, 255); cursor: pointer;" @click="showAboutDialog"><u>we</u></span> are. <br>
                              What <span style="color: rgb(73, 231, 255); cursor: pointer;" @click="showAboutDialog"><u>we</u></span> have. <br>
                              How <span style="color: rgb(73, 231, 255); cursor: pointer;" @click="showAboutDialog"><u>we</u></span> work.
                          </div>
                      </div>

                      <div v-if="centerView == 'services'" style="font-size: 70px; margin-top: -100px" class="">
                          <div class="animate__animated animate__pulse txtServices" style="font-weight: 500;">
                            A few <br>things <span style="color: rgb(73, 231, 255); cursor: pointer;" @click="showServicesDialog"><u>we're</u></span> <br> 
                            <span style="color: rgb(73, 231, 255); cursor: pointer;" @click="showServicesDialog"><u>great</u></span> at
                          </div>
                    </div>

                    <div v-if="centerView == 'contact'" class="centerContact">
                        <div class="animate__animated animate__slideInDown txtContact1" style="font-weight: 500; font-size: 40px;">
                          LET'S GET IN TOUCH!
                        </div>
                        <div class="animate__animated animate__pulse txtContact2" style="font-size: 500; font-size: 40px;">
                          If you require more information or <br>are interested in any of our services,<br>we want to hear from you! <br> Get in touch now!
                        </div>

                        <button @click="goContact" type="button" style="width: 200px; height: 45px; background-color: rgba(25, 39, 49, 1);" 
                          class="btn btn-outline-secondary btn-rounded text-light mt-4 animate__animated animate__slideInUp">
                              Send Us A Message
                        </button>

                      </div>
                      

                      <div class="back-logo-effect" v-if="viewBackLogo" style="width: 650px; height: 650px; position: absolute; margin-top: 0px; pointer-events: none;">
                          <svg width="568" height="502" viewBox="0 0 568 502" fill="none" xmlns="http://www.w3.org/2000/svg" class="svgLogo">
                          <path d="M1.5 288V210L284.5 275L567.5 210V288L284.5 361L1.5 288Z" stroke="white" stroke-opacity="0.5" stroke-width="0.5" class="svg-elem-1"></path>
                          <path d="M1 428V350L284 415L567 350V428L284 501L1 428Z" stroke="white" stroke-opacity="0.5" stroke-width="0.5" class="svg-elem-2"></path>
                          <path d="M0.5 87.5L290 0.5L567.5 76.5V150L290 224L0.5 155V87.5Z" stroke="white" stroke-opacity="0.51" stroke-width="0.5" class="svg-elem-3"></path>
                          </svg>
                      </div>

                  </div>

                  <div class="col-2 right rightSideNextBtn">
                      <div class="goNext" @click="showAboutDialog" v-if="goNextAbout" style="cursor: pointer;"><span id="viewTextGo"></span> <i class="fa fa-arrow-right fa-beat"></i></div>
                      <div class="goNext" @click="showServicesDialog" v-if="goNextServices" style="cursor: pointer;"><span id="viewTextGo"></span> <i class="fa fa-arrow-right fa-beat"></i></div>
                  </div>
              </div>

              <div class="col-12 text-center sub-center" style="margin-top: -100px; z-index: 3;" v-if="windowWidth < 760">
                <div class="goNext" @click="showAboutDialog" v-if="goNextAbout" style="cursor: pointer;"><span id="viewTextGo"></span> <i class="fa fa-arrow-down fa-beat"></i></div>
                <div class="goNext" @click="showServicesDialog" v-if="goNextServices" style="cursor: pointer;"><span id="viewTextGo"></span> <i class="fa fa-arrow-down fa-beat"></i></div>
                <!-- <div class="goNext" @click="btnAbout" v-if="goNextHome" style="cursor: pointer;"><span id="viewTextGo"></span> <i class="fa fa-arrow-down fa-bounce"></i></div> -->
              </div>

              <div class="footer col-12">
                  &copy;&nbsp;<span> {{ yearnow }} </span>&nbsp;Flutterlytics
              </div>
          </div>
        </span>
      </div>
    
    <span v-touch:swipe="swipeHandlerMenu">
      <section class="menu-modal animate__animated" 
        :class="{'animate__slideInDown':isShowDialogShow, 'animate__slideOutUp':isShowDialogHide, 
        'animate__slideInLeft':isShowDialogSideShow, 'animate__slideOutLeft':isShowDialogSideHide, 
        }" 
        v-if="btnMenuDialog || btnAboutDialog || btnServicesDialog">
        
        
          <MenuDialog @menuClicked="menuClickedSelected" v-if="btnMenuDialog"/>
          <AboutDialog v-if="btnAboutDialog"/>
          <ServicesDialog v-if="btnServicesDialog"/>
        

      </section>
    </span>
    

  </div>
</template>

<script>
// @ is an alias to /src
import MenuDialog from '@/components/MenuDialog.vue'
import ServicesDialog from '@/components/ServicesDialog.vue'
import AboutDialog from '@/components/AboutsDialog.vue'

// import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'HomeView',
  components: {
    MenuDialog,
    AboutDialog,
    ServicesDialog
  },
  data: function() {
    return {
      centerView: 'home',
      viewBackLogo: true,
      btnBackColor: ['#00ffff19', 'transparent', 'transparent', 'transparent'],
      btnMenuDialog: false,
      btnAboutDialog: false,
      btnServicesDialog: false,

      isShowDialogShow: false,
      isShowDialogHide: false,
      isShowDialogSideShow: false,
      isShowDialogSideHide: false,

      scrollCount: 1,
      goNextAbout: false,
      goNextServices: false,
      goNextHome: true,

      btnMenuDialogIcon: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      yearnow: 2022,
      settings: {
            "dots": true,
            "focusOnSelect": true,
            "infinite": true,
            "speed": 500,
            "slidesToShow": 3,
            "autoplay": true,
            "initialSlide": 3,
            "draggable": true
        },
        carouselMarginTop: "100px",
    }
  },

  created(){
    document.addEventListener("keydown", function (event) {
        if (event.ctrlKey) {
            event.preventDefault();
        }   
    });
    this.handleWheel();

    document.body.addEventListener("wheel", e=>{
      if(e.ctrlKey)
        e.preventDefault();//prevent zoom
    });

    if(this.windowHeight > 550 && this.windowWidth < 760){
      this.goNextHome = true;
    }else {
      this.goNextHome = false
    }

    // For the Carousel Setting
    if (this.windowHeight == 913) {
      this.carouselMarginTop = "100px"
    } else if (this.windowHeight > 913) {
      const mtop = Number(this.windowHeight) - 913
      this.carouselMarginTop = (100 + Number(mtop)) + "px"
    } else if (this.windowHeight < 913) {
      const mtop = 913 - Number(this.windowHeight)
      this.carouselMarginTop = (100 - Number(mtop)) + "px"
    }

    if (this.windowWidth < 600) {
      this.settings = {
            "dots": true,
            "focusOnSelect": true,
            "infinite": true,
            "speed": 500,
            "slidesToShow": 2,
            "autoplay": true,
            "initialSlide": 2,
            "draggable": true
        }
    } else {
      this.settings = {
            "dots": true,
            "focusOnSelect": true,
            "infinite": true,
            "speed": 500,
            "slidesToShow": 3,
            "autoplay": true,
            "initialSlide": 3,
            "draggable": true
        }
    }

    this.yearnow = new Date().getFullYear()
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods:{
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth

      if(this.windowHeight > 550 && this.windowWidth < 760){
        this.goNextHome = true;
      }else {
        this.goNextHome = false
      }

      // For the Carousel Setting
      if (this.windowHeight == 913) {
        this.carouselMarginTop = "100px"
      } else if (this.windowHeight > 913) {
        const mtop = Number(this.windowHeight) - 913
        this.carouselMarginTop = (100 + Number(mtop)) + "px"
      } else if (this.windowHeight < 913) {
        const mtop = 913 - Number(this.windowHeight)
        this.carouselMarginTop = (100 - Number(mtop)) + "px"
      }

      if (this.windowWidth < 600) {
        this.settings = {
              "dots": true,
              "focusOnSelect": true,
              "infinite": true,
              "speed": 500,
              "slidesToShow": 2,
              "autoplay": true,
              "initialSlide": 2,
              "draggable": true
          }
      } else {
        this.settings = {
              "dots": true,
              "focusOnSelect": true,
              "infinite": true,
              "speed": 500,
              "slidesToShow": 3,
              "autoplay": true,
              "initialSlide": 3,
              "draggable": true
          }
      }
    },

    swipeHandler (e){
      if(!this.isShowDialogSideShow){
        if (e == 'bottom') {
            this.scrollCount--;
            if(this.scrollCount == 1){
              this.btnHome();
            }else if(this.scrollCount == 2){
              this.btnAbout();
            }else if(this.scrollCount == 3){
              this.btnServices();
            }else if(this.scrollCount == 4){
              this.btnContact();
            }else{
              this.scrollCount = 4;
              this.btnContact();
            }
        }else if(e == 'top') {
            this.scrollCount++;
            if(this.scrollCount == 1){
              this.btnHome();
            }else if(this.scrollCount == 2){
              this.btnAbout();
            }else if(this.scrollCount == 3){
              this.btnServices();
            }else if(this.scrollCount == 4){
              this.btnContact();
            }else{
              this.scrollCount = 1;
              this.btnHome();
            }
        }
      }
    },

    swipeHandlerMenu(e){
      if(e == "left" && this.btnAboutDialog){
        this.showMenuDialog();
      }
      if(e == "left" && this.btnServicesDialog){
        this.showMenuDialog();
      }
      if(e == "top" && this.btnMenuDialog){
        this.showMenuDialog();
      }
    },

    menuClickedSelected: function(val){
      if(val == 1){
        this.showMenuDialog();
        this.btnHome();
      }else if(val == 2){
        this.btnMenuDialog = false;
        this.showAboutDialog();
        this.btnAbout();
      }else if(val == 3){
        this.btnMenuDialog = false;
        this.showServicesDialog();
        this.btnServices();
      }
    },

    handleWheel() {
      // var up = 0;
      window.addEventListener('wheel', (event) => {
        if(!this.isShowDialogSideShow){
          if (event.deltaY < 0) {
              this.scrollCount--;
              if(this.scrollCount == 1){
                this.btnHome();
              }else if(this.scrollCount == 2){
                this.btnAbout();
              }else if(this.scrollCount == 3){
                this.btnServices();
              }else if(this.scrollCount == 4){
                this.btnContact();
              }else{
                this.scrollCount = 4;
                this.btnContact();
              }
          }else {
              this.scrollCount++;
              if(this.scrollCount == 1){
                this.btnHome();
              }else if(this.scrollCount == 2){
                this.btnAbout();
              }else if(this.scrollCount == 3){
                this.btnServices();
              }else if(this.scrollCount == 4){
                this.btnContact();
              }else{
                this.scrollCount = 1;
                this.btnHome();
              }
          }
        }
      })
    },

    showMenuDialog(){
      if(this.btnMenuDialogIcon){
        
        if(this.btnAboutDialog || this.btnServicesDialog){
          this.isShowDialogSideShow = false;
          this.isShowDialogSideHide = true;

          this.isShowDialogShow = false;
          this.isShowDialogHide = false;
        }else{
          this.isShowDialogSideShow = false;
          this.isShowDialogSideHide = false;

          this.isShowDialogShow = false;
          this.isShowDialogHide = true;
        }
        
        setTimeout(() => {
          this.btnMenuDialog = false;
          this.btnAboutDialog = false;
          this.btnServicesDialog = false;
          this.btnMenuDialogIcon = false;
        }, 1000);
      }else{
        this.btnMenuDialog = true;
        this.btnMenuDialogIcon = true;

        this.isShowDialogShow = true;
        this.isShowDialogHide = false;

        this.isShowDialogSideShow = false;
        this.isShowDialogSideHide = false;
      }

    },

    showAboutDialog(){
      // if(this.btnMenuDialogIcon){
      //   this.isShowDialogSide = false;
      //   setTimeout(() => {
      //     this.btnAboutDialog = false;
      //     this.btnMenuDialogIcon = false;
      //   }, 1000);
      // }else{
        this.btnAboutDialog = true;
        this.btnMenuDialogIcon = true;

        this.isShowDialogSideShow = true;
        this.isShowDialogSideHide = false;

        this.isShowDialogShow = false;
        this.isShowDialogHide = false;
      // }
    },

    showServicesDialog(){
      // if(this.btnMenuDialogIcon){
      //   this.isShowDialogSide = false;
      //   setTimeout(() => {
      //     this.btnServicesDialog = false;
      //     this.btnMenuDialogIcon = false;
      //   }, 1000);
      // }else{
        this.btnServicesDialog = true;
        this.btnMenuDialogIcon = true;

        this.isShowDialogSideShow = true;
        this.isShowDialogSideHide = false;

        this.isShowDialogShow = false;
        this.isShowDialogHide = false;
      // }
    },

    btnHome(){
      this.centerView = 'home';
      this.btnBackColor = ['#00ffff19', 'transparent', 'transparent', 'transparent'];
      this.viewBackLogo = false;
      this.goNextAbout = false;
      this.goNextServices = false;
      setTimeout(() => {
        this.viewBackLogo = true;
      }, 200);
      if(this.windowHeight > 550 && this.windowWidth < 760){
        this.goNextHome = true;
      }else {
        this.goNextHome = false
      }
    },

    btnAbout(){
      this.centerView = 'about';
      this.btnBackColor = ['transparent', '#00ffff19', 'transparent', 'transparent'];
      this.viewBackLogo = false;
      this.goNextAbout = true;
      this.goNextHome = false;
      this.goNextServices = false;
      setTimeout(() => {
        this.viewBackLogo = true;
      }, 200);
    },

    btnServices(){
      this.centerView = 'services';
      this.btnBackColor = ['transparent', 'transparent', '#00ffff19', 'transparent'];
      this.viewBackLogo = false;
      this.goNextAbout = false;
      this.goNextHome = false;
      this.goNextServices = true;
      setTimeout(() => {
        this.viewBackLogo = true;
      }, 200);
    },

    btnContact(){
      this.centerView = 'contact';
      this.btnBackColor = ['transparent', 'transparent', 'transparent', '#00ffff19'];
      this.viewBackLogo = false;
      this.goNextAbout = false;
      this.goNextHome = false;
      this.goNextServices = false;
      setTimeout(() => {
        this.viewBackLogo = true;
      }, 200);
    },

    goContact(){
      var _0x796e=["\x6D\x61\x69\x6C\x74\x6F\x3A\x63\x6F\x6E\x74\x61\x63\x74\x75\x73\x40\x66\x6C\x75\x74\x74\x65\x72\x6C\x79\x74\x69\x63\x73\x2E\x63\x6F\x6D","\x6F\x70\x65\x6E"];window[_0x796e[1]](_0x796e[0])
    }

  }
  
}
</script>

<style scope>
  .menu-modal {
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(25, 39, 49, 0.992);
  }

  .carou {
    background-color: transparent;
    width: 600px !important;
    margin: auto;
    height: 70px;
    /* border-top: 1px solid grey;
    border-bottom: 1px solid grey; */
    /* border: 1px solid green */
  }

  .carou-small {
    background-color: transparent;
    width: 95% !important;
    margin: auto;
    height: 90px;
    /* border-top: 1px solid grey;
    border-bottom: 1px solid grey; */
    /* border: 1px solid green */
  }

  .slide-cont {
    margin-left: 10px; 
    margin-right: 10px; 
    margin-top: 10px !important; 
    margin-bottom: auto;
    /* border: 1px solid yellow; */
    width: 25%;
    height: 100px;
    /* background-color: rgba(209, 209, 209, 0.151); */
  }

  .slide-cont-small {
    margin-left: 10px; 
    margin-right: 10px; 
    margin-top: 10px !important; 
    margin-bottom: auto;
    /* border: 1px solid yellow; */
    width: 10%;
    height: 70px;
    /* background-color: rgba(209, 209, 209, 0.151); */
  }

  .slick-arrow .slick-next {
    background-color: green !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust this as needed */
  }

  .inner {
    margin: 20px; /* Adds some space around each div */
  }
</style>
