<template>
  <div class="menudialog text-white" style="">
    <div class="row" style="margin-top: 100px;">

      <div class="col-12" style=" font-size: 15px; font-weight: 300;">

        <div class="scrollbar" id="style-1">
          <div class="force-overflow">

              <div class="w3-container">
                <h3>A few things we're great at</h3>

                <div class="zigzag-timeline__item">
                    <h4><i class="fa-solid fa-chart-line fa-1x me-3" style="font-size:35px;"></i>PAY-PER-CLICK ADVERTISING</h4>
                    <div class="zigzag-timeline__milestone"></div>
                    <p>Leverage the power of PPC advertising to achieve measurable results. Our analytic-driven strategies optimize every aspect of your business and ensure brand visibility.</p>
                </div>

                <div class="zigzag-timeline__item">
                    <h4><i class="fa-solid fa-laptop me-3" style="font-size:35px;"></i>EMAIL MARKETING</h4>
                    <div class="zigzag-timeline__milestone"></div>
                    <p>Email marketing solutions that will help you connect with your audience on a personal level and drive sales. Tailored-fit campaigns to your brand that resonate with customers. We offer automation and analytics to build lasting relationships with your customers and optimize sales.</p>
                </div>

                <div class="zigzag-timeline__item">
                    <h4><i class="fa-solid fa-database fa-2x me-3" style="font-size:35px;"></i>SEARCH ENGINE OPTIMIZATION</h4>
                    <div class="zigzag-timeline__milestone"></div>
                    <p>We utilize cutting-edge methodologies to position your brand as a leader in organic search results.</p>
                </div>

                <div class="zigzag-timeline__item">
                    <h4><i class="fa-solid fa-money-bill-1 fa-2x me-3" style="font-size:35px;"></i>MEDIA BUYING</h4>
                    <div class="zigzag-timeline__milestone"></div>
                    <p>Expertise in media buying that prioritizes brand safety, user privacy, and compliance while optimizing performance and driving results. By strategically placing ads and media, we enhance the consumer journey, encourage purchases, and foster positive experiences to build brand loyalty and maximize ROI.</p>
                </div>

              </div>

            </div>
          </div>

        </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'ServiceDialog',
  components: {
    
  },
  data: function() {
    return {

    }
  },
  methods:{


  }
  
}
</script>
