<template>
    <div class="menudialog text-white" style="">
      <div class="row" style="margin-top: 100px;">

        <div class="col-12" style=" font-size: 15px; font-weight: 300;">

          <div class="scrollbar" id="style-1">
            <div class="force-overflow">

                <div class="w3-container">
                  <h3>ABOUT US</h3>
                  <p>We provide quick and cost-effective solutions to reach your target audience wherever they may be and speak directly to their interests. We want to help you get the results you want!</p>

                  <div class="zigzag-timeline__item">
                      <h3>Who we are.</h3>
                      <div class="zigzag-timeline__milestone"><i class="fa-solid fa-users fa-2x"></i></div>
                      <p>Flutterlytics is a global marketing consultancy with unique and proprietary technology running through its core. We provide clients with branding and marketing solutions supported by our analytics and expertise.</p>
                  </div>

                  <div class="zigzag-timeline__item">
                      <h3>What we have.</h3>
                      <div class="zigzag-timeline__milestone"><i class="fa-solid fa-square-poll-vertical fa-3x" style="margin-top: -10px"></i></div>
                      <p>We have an experienced team on board, versed in every form of digital branding and advertising. We incorporate industry experts and pioneering technology to develop custom-tailored strategies for each client.</p>
                  </div>

                  <div class="zigzag-timeline__item">
                      <h3>How we work.</h3>
                      <div class="zigzag-timeline__milestone"><i class="fa-solid fa-message fa-2x"></i></div>
                      <p>We build long-term relationships with our clients, connecting with each and every one of them. We know each brand, product, and vertical has distinct marketing strategies and objectives and we take specific approaches to address each one.</p>
                  </div>

                </div>

              </div>
            </div>

          </div>
        
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  
  export default {
    name: 'AboutDialog',
    components: {
      
    },
    data: function() {
      return {

      }
    },
    methods:{

  
    }
    
  }
  </script>
  