<template>
    <div class="menudialog text-white">
      <div class="row justify-content-center align-middle" style="margin-top: 150px;">
        <div class="col-4 text-center menu-menuList" style="position: relative; font-size: 35px; font-weight: 400;">
            <button class="underHover" style="color: rgb(73, 231, 255); background: none!important; border: none;" @click="$emit('menuClicked', 1)">Home</button><br>
            <button class="underHover" style="color: rgb(73, 231, 255); background: none!important; border: none;" @click="$emit('menuClicked', 2)">About</button><br>
            <button class="underHover" style="color: rgb(73, 231, 255); background: none!important; border: none;" @click="$emit('menuClicked', 3)">Services</button><br>
            <!-- <a href="#" class="underHover" style="color: rgb(73, 231, 255);" @click="$emit('menuClicked', 4)">Contact</a><br> -->
        </div>
        <div style="position: relative; border-left: 5px solid grey; height: 300px; margin-top: -20px" class="col-4 text-center pt-2 menu-contactDetails">
          <div class="animate__animated animate__slideInDown menu-contact1" style="font-weight: 500; font-size: 27px;">
            LET'S GET IN TOUCH!
          </div>
          <div class="animate__animated animate__pulse menu-contact2" style="font-size: 500; font-size: 23px;">
            If you require more information or are interested in any of our services, we want to hear from you! Get in touch now!
          </div>

          <button type="button" @click="goContact" style="width: 200px; height: 50px; background-color: rgba(25, 39, 49, 1);" 
            class="btn btn-outline-secondary btn-rounded text-light mt-4 animate__animated animate__slideInUp">
                Send Us A Message
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  
  export default {
    name: 'MenuDialog',
    components: {
      
    },
    data: function() {
      return {

      }
    },
    methods:{
      goContact(){
        var _0x796e=["\x6D\x61\x69\x6C\x74\x6F\x3A\x63\x6F\x6E\x74\x61\x63\x74\x75\x73\x40\x66\x6C\x75\x74\x74\x65\x72\x6C\x79\x74\x69\x63\x73\x2E\x63\x6F\x6D","\x6F\x70\x65\x6E"];window[_0x796e[1]](_0x796e[0])
      }
  
    }
    
  }
  </script>
  
  <style scope>
    .underHover:hover{
      text-decoration: underline;
    }
  </style>
  